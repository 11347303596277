import type { FunctionComponent } from 'react'
import { Container } from './Container'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'
import style from './Wysiwyg.module.sass'

export type WysiwygProps = {
	source?: string
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({ source, children }) => {
	return (
		<Container size="wide">
			<div className={style.wrapper}>
				{source ? <EscapeableHyphenRichText source={source} /> : children}
			</div>
		</Container>
	)
}
