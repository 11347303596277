import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { TileType } from '../../generated/content'
import type { AppListResult } from '../data/content/AppListFragment'
import s from './AppList.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'

export type AppListProps = {
	appList: AppListResult
}

export const AppList: FunctionComponent<AppListProps> = ({ appList }) => {
	const firstColumnItems = appList.items.filter((_item, index) => index % 2 === 0)
	const secondColumnItems = appList.items.filter((_item, index) => index % 2 === 1)
	return (
		<Container size="normal" guttersSize="mobileNarrow">
			<div className={s.AppList}>
				{[firstColumnItems, secondColumnItems].map((column, columnIndex) => (
					<div key={columnIndex} className={s.Column}>
						{column.map(
							(app) =>
								app.app?.link?.url && (
									<Link href={app.app.link.url} key={app.id}>
										<div className={clsx(s.App, app.app?.blueTile && s.isBlue)}>
											<div className={s.TitleBox}>
												<h3 className={s.Title}>{app.app?.title}</h3>
												<Button text="Více" color={app.app?.blueTile ? 'white' : 'blue'} />
											</div>
											{app.app?.tileText && <p className={s.TileText}>{app.app?.tileText}</p>}
											{app.app &&
												(app.app.tileType === TileType.image && app.app.tileImage ? (
													app.app.tileImage && (
														<div
															className={s.TileImage}
															style={{ '--AppList-tileImage-width': app.app.tileImage.width }}>
															<ContemberImage image={app.app.tileImage} />
														</div>
													)
												) : app.app.tileType === TileType.video && app.app.tileVideo ? (
													<video
														className={s.TileVideo}
														autoPlay
														loop
														muted
														playsInline
														poster={app.app.tileVideo.poster?.url}>
														<source src={app.app.tileVideo.src} type={app.app.tileVideo.type} />
													</video>
												) : null)}
										</div>
									</Link>
								)
						)}
					</div>
				))}
			</div>
		</Container>
	)
}
