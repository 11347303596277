import { z } from 'zod'

export const ContactFormInputs = () =>
	z.object({
		name: z.string().min(1),
		company: z.string().optional(),
		email: z.string().min(1),
		telephone: z.string().optional(),
		note: z.string().optional(),
	})

export type ContactFormInputsResult = z.infer<ReturnType<typeof ContactFormInputs>>
