import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { ContentAlignment } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import s from './Button.module.sass'
import { Container } from './Container'
import { Icon } from './Icon'
import { Link } from './Link'

export type ButtonProps = {
	text?: string
	link?: LinkResult
	buttonSize?: boolean
	color?: 'blue' | 'white'
}

export const Button: FunctionComponent<ButtonProps> = ({
	text,
	link,
	buttonSize,
	color = 'blue',
}) => {
	return (
		<div className={clsx(s.Button, buttonSize && s.ButtonSize, s[`ButtonColor_${color}`])}>
			{text && (
				<div className={s.NonLink}>
					<ButtonTextWithIcon text={text} />
				</div>
			)}
			{link && (
				<Link className={s.Link} link={link}>
					{link.title && <ButtonTextWithIcon text={link.title} />}
				</Link>
			)}
		</div>
	)
}

export const BlockButton: FunctionComponent<
	ButtonProps & {
		aligning?: ContentAlignment
	}
> = ({ text, link, aligning, buttonSize }) => {
	return (
		<Container size="wide">
			<div
				className={clsx(
					s.BlockButton,
					aligning === ContentAlignment.center && s.Center,
					aligning === ContentAlignment.right && s.Right
				)}>
				<Button text={text} link={link} buttonSize={buttonSize} />
			</div>
		</Container>
	)
}

const ButtonTextWithIcon: FunctionComponent<{ text: string }> = ({ text }) => {
	const textSplitBySpace = text.split(' ')
	const textWithOnlyLastWord = textSplitBySpace[textSplitBySpace.length - 1]
	textSplitBySpace.pop()
	const textWithoutLastWord = textSplitBySpace.join(' ')
	return (
		<>
			<span className={s.Text}>
				{textWithoutLastWord}
				{textWithOnlyLastWord && ' '}
			</span>
			<span className={s.TextIcon}>
				{textWithOnlyLastWord && textWithOnlyLastWord}
				<Icon name="arrow" />
			</span>
		</>
	)
}
