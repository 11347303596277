import type { FunctionComponent } from 'react'
import type { GenericPageResult } from '../data/content/GenericPageFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'
import s from './HomePage.module.sass'
import { LeadContent } from './LeadContent'

export type GenericPageProps = GenericPageResult

export const GenericPage: FunctionComponent<GenericPageProps> = ({
	content,
	leadTitle,
	leadParagraph,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={s.LeadContent}>
					<LeadContent title={leadTitle} leadParagraph={leadParagraph} isTopSpacing />
				</div>
			</Container>
			{content && <ContentRenderer content={content} />}
		</div>
	)
}
