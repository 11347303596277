import type { CSSProperties, FunctionComponent } from 'react'
import type { Background, VerticalAlignment } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumItemResult } from '../data/content/MediumItemFragment'
import s from './Boxes.module.sass'
import { BoxTile } from './BoxTile'
import { Container } from './Container'

export type BoxesProps = {
	boxTitle?: string
	boxText?: string
	boxTitleSecond?: string
	boxTextSecond?: string
	link?: LinkResult
	linkSecond?: LinkResult
	media?: MediumItemResult[]
	mediaSecond?: MediumItemResult[]
	width?: number
	background?: Background
	backgroundSecond?: Background
	verticalAlignment?: VerticalAlignment
	verticalAlignmentSecond?: VerticalAlignment
}

export const Boxes: FunctionComponent<BoxesProps> = ({
	boxTitle,
	boxTitleSecond,
	boxText,
	boxTextSecond,
	link,
	linkSecond,
	media,
	mediaSecond,
	width = 50,
	background,
	backgroundSecond,
	verticalAlignment,
	verticalAlignmentSecond,
}) => {
	return (
		<Container size="normal" guttersSize="mobileNarrow">
			<div
				className={s.Boxes}
				style={
					{
						'--Boxes-width-first': `${width}fr`,
						'--Boxes-width-second': `${100 - width}fr`,
					} as CSSProperties
				}>
				<BoxTile
					title={boxTitle}
					text={boxText}
					link={link}
					media={media}
					background={background}
					verticalAlignment={verticalAlignment}
				/>
				<BoxTile
					title={boxTitleSecond}
					text={boxTextSecond}
					link={linkSecond}
					media={mediaSecond}
					backgroundSecond={backgroundSecond}
					verticalAlignmentSecond={verticalAlignmentSecond}
				/>
			</div>
		</Container>
	)
}
