// import type { LightboxSource } from '@mangoweb/fslightbox'
// import { useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'

export type GalleryProps = {
	mediumList: MediumListResult
}

export const Gallery: FunctionComponent<GalleryProps> = ({ mediumList }) => {
	// const items = mediumList.items
	// 	.map((item) => {
	// 		if (item.medium) {
	// 			const source = item.medium.video?.src ?? item.medium.image?.url
	// 			if (source) {
	// 				const result: LightboxSource = {
	// 					source,
	// 					type: item.medium.video ? 'video' : 'image',
	// 				}
	// 				return result
	// 			}
	// 		}
	// 	})
	// .filter(filterNonEmpty)

	// const lightbox = useLightbox(items, {
	// 	onSlideActivate: (current, previous) => {
	// 		const getSlideVideo = (slideIndex: number) => {
	// 			const video = document.querySelector(
	// 				`.fslightbox-absoluted.fslightbox-full-dimension.fslightbox-flex-centered:nth-child(${
	// 					slideIndex + 1
	// 				}) video`
	// 			)
	// 			if (video instanceof HTMLVideoElement) {
	// 				return video
	// 			}
	// 		}

	// 		requestAnimationFrame(() => {
	// 			if (previous !== null) {
	// 				getSlideVideo(previous.slideIndex)?.pause()
	// 			}
	// 			getSlideVideo(current.slideIndex)?.play()
	// 		})
	// 	},
	// })

	return (
		<div className={clsx(s.Gallery, mediumList?.items.length > 1 && s.isMultipleColumns)}>
			{mediumList?.items.map((item) => (
				<div key={item.id} className={s.ItemWrapper}>
					<div className={s.Item}>
						{item.medium?.type === 'image' && item.medium.image && (
							<>
								<div className={s.CarouselWrapper}>
									<div className={s.Image}>
										{/* <button className={s.Button} onClick={() => lightbox?.open(i)}> */}
										<ContemberImage image={item.medium.image} layout="intrinsic" />
										{/* </button> */}
									</div>
								</div>
							</>
						)}
						{item.medium?.type === 'video' && item.medium.video?.poster && (
							<div className={s.ImageWrapper}>
								{/* <div className={s.Play}>
										<Icon name="play" />
									</div> */}
								<div className={s.CarouselWrapper}>
									<div
										className={s.Video}
										style={{
											'--Gallery-video-width': item.medium.video.width,
											'--Gallery-video-height': item.medium.video.height,
										}}>
										{/* @TODO: video is displayed only if video contains poster (item.medium.video.poster) */}
										{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
										<video
											className={s.VideoIn}
											autoPlay
											loop
											muted
											playsInline
											poster={item.medium.video?.poster.url}>
											<source src={item.medium.video.src} type={item.medium.video.type} />
										</video>
									</div>
								</div>
							</div>
						)}
						<p className={s.Alt}>{item.medium?.image?.alt ?? item.medium?.video?.videoInfo}</p>
					</div>
				</div>
			))}
			<span className={s.LastPlaceholder}></span>
		</div>
	)
}
