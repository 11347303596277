import type { FunctionComponent } from 'react'
import { Container } from './Container'
import { Gallery, GalleryProps } from './Gallery'
import styles from './GalleryBlock.module.sass'

export type GalleryBlockProps = GalleryProps

export const GalleryBlock: FunctionComponent<GalleryBlockProps> = ({ mediumList }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					<Gallery mediumList={mediumList} />
				</div>
			</Container>
		</div>
	)
}
