import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { PricingTableResult } from '../data/content/PricingTableFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './PricingTable.module.sass'
import { PricingTableGroup } from './PricingTableGroup'

export type PricingTableProps = PricingTableResult

export const PricingTable: FunctionComponent<PricingTableProps> = ({
	title,
	titleImage,
	headerColumns,
	groups,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<table className={styles.table}>
					<thead className={styles.header}>
						<tr>
							<th className={clsx(styles.headerCell, styles.is_inCorner)}>
								<div className={styles.tableCorner}>
									{titleImage && (
										<div
											className={styles.tableImage}
											style={{
												'--PricingTable-image-width': titleImage.width,
												'--PricingTable-image-height': titleImage.height,
											}}>
											<ContemberImage image={titleImage} layout="fill" objectFit="cover" />
										</div>
									)}
									{title && <h3 className={styles.tableName}>{title}</h3>}
								</div>
							</th>
							{headerColumns.map((headerColumn) => (
								<th key={headerColumn.id} className={styles.headerCell}>
									{headerColumn.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody className={styles.body}>
						{groups.map((group, index) => (
							<PricingTableGroup
								key={group.id}
								{...group}
								columnCount={headerColumns.length}
								isFirstGroup={index === 0}
							/>
						))}
					</tbody>
				</table>
			</Container>
		</div>
	)
}
