import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { PricingTableRowResult } from '../data/content/PricingTableRowFragment'
import { PricingTableColumn } from './PricingTableColumn'
import styles from './PricingTableRow.module.sass'

export type PricingTableRowProps = PricingTableRowResult & {
	isTitleShifted?: boolean
}

export const PricingTableRow: FunctionComponent<PricingTableRowProps> = ({
	title,
	columns,
	isTitleShifted: isInGroup,
}) => {
	return (
		<>
			<td className={clsx(styles.cell, styles.is_header, isInGroup && styles.is_shifted)}>
				{title && title}
			</td>
			{columns.map((column) => (
				<td key={column.id} className={styles.cell}>
					<PricingTableColumn {...column} />
				</td>
			))}
		</>
	)
}
