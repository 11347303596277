import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Footer.module.sass'
import { Link } from './Link'

export type FooterProps = {
	footer: FooterResult
}

export function Footer(props: FooterProps) {
	return (
		<div className={s.Background}>
			<Container size="normal">
				<div className={s.SpecialLinks}>
					<Link className={s.DeveloperButton} link={props.footer.developerButton} />
					<Button link={props.footer.tryButton} />
				</div>
				{props.footer.logo && (
					<div className={s.Logo}>
						<ContemberImage image={props.footer.logo} />
					</div>
				)}
				<div className={s.FooterColumns}>
					<div className={s.ContactWrapper}>
						<p className={s.Contact}>{props.footer.contactLabel}</p>
						<Link href={`mailto:${props.footer.email}`} className={s.Email}>
							{props.footer.email}
						</Link>
						<p className={s.Copyright}>{props.footer.copyright}</p>
						<p className={s.Address}>{props.footer.address}</p>
					</div>
					<div className={s.LinkColumns}>
						{props.footer.linkGroups?.items.map((item) => (
							<div className={s.LinkColumn} key={item.id}>
								{item.group.map((item) => (
									<React.Fragment key={item.id}>
										{item.links?.items.map((item) => (
											<Link className={s.Link} link={item.link} key={item.id}>
												{item.icon && (
													<div className={s.Icon}>
														<ContemberImage image={item.icon} />
													</div>
												)}
												<div className={s.LinkTitleWrapper}>
													<p className={s.LinkTitle}>{item.link?.title}</p>
													<p className={s.LinkDescription}>{item.description}</p>
												</div>
											</Link>
										))}
									</React.Fragment>
								))}
							</div>
						))}
					</div>
				</div>
				{props.footer.madeBy && (
					<div className={s.MadeBy}>
						<RichTextRenderer source={props.footer.madeBy} />
					</div>
				)}
			</Container>
		</div>
	)
}
