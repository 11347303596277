import {
	RichTextElement,
	RichTextLeaf,
	RichTextRenderer,
	RootEditorNode,
} from '@contember/react-client'

export const deserializeWithHyphensSupport = <
	CustomElements extends RichTextElement = never,
	CustomLeaves extends RichTextLeaf = never
>(
	source: string
): RootEditorNode<CustomElements, CustomLeaves> => {
	try {
		return JSON.parse(source, (key, value) => {
			if (key === 'text') {
				return value.replace(/\\-/g, '\u00AD')
			}
			return value
		})
	} catch (e) {
		return {
			formatVersion: 0,
			children: [],
		}
	}
}

export const EscapeableHyphenRichText: typeof RichTextRenderer = (props) => {
	return <RichTextRenderer {...props} deserialize={deserializeWithHyphensSupport} />
}
