import clsx from 'clsx'
import { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocalLoading } from 'shared-loading-indicator'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { ContactFormResult } from '../data/content/ContactFormFragment'
import { ContactFormInputs } from '../forms/ContactForm/ContactFormInputs'
import { validateZod } from '../validators/utils/validateZod'
import styles from './ContactForm.module.sass'
import { Input } from './Input'
import { TextArea } from './TextArea'

export type ContactFormProps = ContactFormResult

export const api = createFetcher<API>('/api/v1/[handler]')

export const ContactForm: FunctionComponent<ContactFormProps> = ({
	nameLabel,
	namePlaceholder,
	companyLabel,
	companyPlaceholder,
	emailLabel,
	emailPlaceholder,
	telephoneLabel,
	telephonePlaceholder,
	noteLabel,
	notePlaceholder,
	successMessage,
	errorMessage,
}) => {
	const [isSubmitted, setSubmitted] = useState(false)
	const [_, setIsLoading] = useLocalLoading()

	const mutation = useMutation(async (formData: FormData) => {
		const validatedData = validateZod(formData, ContactFormInputs())

		return api('contactForm', validatedData)
	})

	const handleSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			if (!event.currentTarget) {
				return
			}
			setIsLoading(true)
			event.preventDefault()

			const form: HTMLFormElement = event.currentTarget
			const formData = new FormData(form)

			await mutation
				.mutateAsync(formData)
				.then((result) => {
					if (result) {
						if (!result.contemberStatus.ok) {
							console.error('Contember fails')
						}
					}
				})
				.catch((e) => {
					console.error(e)
				})
			setSubmitted(true)

			setTimeout(() => {
				mutation.reset()
				setSubmitted(false)
				setIsLoading(false)
				event.currentTarget
				form.reset()
			}, 3000)
		},
		[mutation, setIsLoading]
	)

	return (
		<form className={styles.wrapper} onSubmit={handleSubmit}>
			<div
				className={clsx(
					styles.notification,
					isSubmitted && styles.is_visible,
					mutation.isSuccess && styles.is_success,
					mutation.isError && styles.is_error
				)}>
				<div className={styles.notificationIn}>
					<div className={styles.notificationImage}></div>
					<span className={styles.notificationMessage}>
						{mutation.isSuccess ? successMessage : mutation.isError ? errorMessage : null}
					</span>
				</div>
			</div>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={clsx(styles.label, styles.is_name)}>
				{/* @TODO: validation */}
				{nameLabel && <span className={styles.labelText}>{nameLabel}*</span>}
				<div className={styles.input}>
					<Input placeholder={namePlaceholder} name="name" required />
				</div>
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={clsx(styles.label, styles.is_company)}>
				{companyLabel && <span className={styles.labelText}>{companyLabel}</span>}
				<div className={styles.input}>
					<Input placeholder={companyPlaceholder} name="company" />
				</div>
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={clsx(styles.label, styles.is_email)}>
				{/* @TODO: validation */}
				{emailLabel && <span className={styles.labelText}>{emailLabel}*</span>}
				<div className={styles.input}>
					<Input placeholder={emailPlaceholder} type="email" name="email" required />
				</div>
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={clsx(styles.label, styles.is_telephone)}>
				{telephoneLabel && <span className={styles.labelText}>{telephoneLabel}</span>}
				<div className={styles.input}>
					<Input placeholder={telephonePlaceholder} name="telephone" type="tel" />
				</div>
			</label>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={clsx(styles.label, styles.is_note)}>
				{noteLabel && <span className={styles.labelText}>{noteLabel}</span>}
				<div className={styles.input}>
					<TextArea placeholder={notePlaceholder} name="note" />
				</div>
			</label>
			<button type="submit" className={styles.button}>
				Odeslat
			</button>
		</form>
	)
}
