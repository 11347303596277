import type { HomePageResult } from '../data/content/HomePageFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './HomePage.module.sass'
import { LeadContent } from './LeadContent'

export type HomeProps = {
	homePage: HomePageResult
}

export function HomePage(props: HomeProps) {
	return (
		<>
			<div className={s.HomePage}>
				<Container>
					<div className={s.LeadContent}>
						<LeadContent
							title={props.homePage.title}
							leadParagraph={props.homePage.leadParagraph}
							isTopSpacing
						/>
					</div>
				</Container>
				{props.homePage.content && <ContentRenderer content={props.homePage.content} />}
			</div>
		</>
	)
}
