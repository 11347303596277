import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ContentAlignment } from '../../generated/content'
import type { ContentReferenceContactFormResult } from '../data/content/ContentReferenceContactFormFragment'
import type { MediumListResult } from '../data/content/MediumListFragment'
import s from './AsideContent.module.sass'
import { ContactForm } from './ContactForm'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'

export type AsideContentProps = {
	title: string
	text?: string
	mediumList?: MediumListResult
	alignmentReverse: ContentAlignment
	isContactForm?: boolean
	contactForm?: ContentReferenceContactFormResult
}

export const AsideContent: FunctionComponent<AsideContentProps> = ({
	title,
	text,
	mediumList,
	alignmentReverse,
	isContactForm = false,
	contactForm,
}) => {
	return (
		<div className={s.Wrapper}>
			<Container size="wide">
				<div className={clsx(s.AsideContent, s[`isText_${alignmentReverse}`])}>
					<div className={s.Box}>
						<div className={s.Content}>
							<h2 className={s.Title}>
								<EscapeableHyphenRichText source={title} />
							</h2>
							<div className={s.Text}>{text && <EscapeableHyphenRichText source={text} />}</div>
						</div>
					</div>
					<div className={s.Box}>
						{mediumList?.items.map((item) => {
							const type = item.medium?.type
							return (
								<div key={item.id} className={clsx(s.BoxIn, s[`is_${type}`])}>
									{type === 'image' && item.medium?.image ? (
										<div
											className={s.Image}
											style={{
												'--AsideContent-image-width': item.medium.image.width,
												'--AsideContent-image-height': item.medium.image.height,
											}}>
											<ContemberImage image={item.medium.image} layout="fill" objectFit="cover" />
										</div>
									) : type === 'video' && item.medium?.video ? (
										// eslint-disable-next-line jsx-a11y/media-has-caption
										<video
											className={s.video}
											autoPlay
											loop
											muted
											playsInline
											poster={item.medium.video?.poster?.url}>
											<source src={item.medium.video.src} type={item.medium.video.type} />
										</video>
									) : null}
								</div>
							)
						})}
						{isContactForm && (
							<div className={s.Form}>
								<ContactForm {...contactForm?.form?.localesByLocale} />
							</div>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
