import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { ContentAlignment } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumItemResult } from '../data/content/MediumItemFragment'
import { BlockButton } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './CustomerJumbotron.module.sass'

export type CustomerJumbotronProps = {
	titleFirstLine: string
	titleSecondLine?: string
	titleThirdLine?: string
	text?: string
	link?: LinkResult
	aligning?: ContentAlignment
	media?: MediumItemResult[]
}

export const CustomerJumbotron: FunctionComponent<CustomerJumbotronProps> = ({
	titleFirstLine,
	titleSecondLine,
	titleThirdLine,
	text,
	link,
	aligning,
	media,
}) => {
	return (
		<Container size="normal">
			<div className={s.CustomerJumbotron}>
				<div className={s.TitleWrapper}>
					<h2 className={s.Title}>
						<span className={clsx(s.TitleLine, s.FirstLine)}>{titleFirstLine}</span>
						<span className={clsx(s.TitleLine, s.SecondLine)}>{titleSecondLine}</span>
						<span className={clsx(s.TitleLine, s.ThirdLine)}>{titleThirdLine}</span>
					</h2>
					<div className={s.Media}>
						{media?.map((item) => (
							<div
								key={item.id}
								className={s.MediaItem}
								style={{ '--CustomerJumbotron-mediaItem-width': item.medium?.image?.width }}>
								{item.medium?.image && <ContemberImage image={item.medium.image} />}
							</div>
						))}
					</div>
				</div>
				<p className={s.Text}>{text}</p>
				<div className={clsx(s.Link, aligning === ContentAlignment.center && s.Center)}>
					<BlockButton link={link} aligning={aligning} />
				</div>
			</div>
		</Container>
	)
}
