import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react'
import styles from './TextArea.module.sass'

export type TextAreaProps = Pick<
	DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
	| 'name'
	| 'autoComplete'
	| 'value'
	| 'onChange'
	| 'disabled'
	| 'readOnly'
	| 'placeholder'
	| 'required'
	| 'hidden'
>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{ name, autoComplete, value, onChange, disabled, readOnly, placeholder, required, hidden },
		ref
	) => {
		return (
			<textarea
				className={styles.wrapper}
				name={name}
				autoComplete={autoComplete}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				required={required}
				hidden={hidden}
				ref={ref}
			/>
		)
	}
)

TextArea.displayName = 'TextArea'
