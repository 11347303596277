import type { AppResult } from '../data/content/AppFragment'
import s from './App.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import { LeadContent } from './LeadContent'

export type AppProps = {
	app: AppResult
}

export function App(props: AppProps) {
	return (
		<div className={s.App}>
			<Container>
				<div className={s.LeadContent}>
					{props.app.headerImage && (
						<div
							className={s.Image}
							style={{
								'--App-headerImage-width': props.app.headerImage.width,
								'--App-headerImage-height': props.app.headerImage.height,
							}}>
							<ContemberImage image={props.app.headerImage} layout="fill" objectFit="cover" />
						</div>
					)}
					<LeadContent title={props.app.leadTitle} leadParagraph={props.app.leadParagraph} />
				</div>
			</Container>
			{props.app.content && <ContentRenderer content={props.app.content} />}
		</div>
	)
}
