import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/content'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import type { ContentResult } from '../data/content/ContentFragment'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { AppList } from './AppList'
import { AsideContent } from './AsideContent'
import { Box } from './Box'
import { Boxes } from './Boxes'
import { BlockButton } from './Button'
import styles from './ContentRenderer.module.sass'
import { CustomerJumbotron } from './CustomerJumbotron'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'
import { GalleryBlock } from './GalleryBlock'
import { PricingTable } from './PricingTable'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	asideContent({ reference }) {
		return (
			reference.primaryText &&
			reference.alignment && (
				<AsideContent
					title={reference.primaryText}
					text={reference.secondaryText}
					mediumList={reference.media}
					alignmentReverse={reference.alignment}
					isContactForm={reference.isContactForm}
					// contactForm is only one
					contactForm={reference.contactForm[0]}
				/>
			)
		)
	},
	gallery({ reference }) {
		return reference.media && <GalleryBlock mediumList={reference.media} />
	},
	button({ reference }) {
		return (
			reference.link &&
			reference.alignment && <BlockButton link={reference.link} aligning={reference.alignment} />
		)
	},
	appTiles({ reference }) {
		return reference.appList && <AppList appList={reference.appList} />
	},
	customerJumbotron({ reference }) {
		return (
			reference.primaryText &&
			reference.media?.items && (
				<CustomerJumbotron
					titleFirstLine={reference.primaryText}
					titleSecondLine={reference.secondaryText}
					titleThirdLine={reference.tertiaryText}
					text={reference.quaternaryText}
					aligning={reference.alignment}
					link={reference.link}
					media={reference.media.items}
				/>
			)
		)
	},
	boxes({ reference }) {
		return (
			<Boxes
				boxTitle={reference.primaryText}
				boxText={reference.secondaryText}
				boxTitleSecond={reference.tertiaryText}
				boxTextSecond={reference.quaternaryText}
				link={reference.link}
				linkSecond={reference.linkSecond}
				media={reference.media?.items}
				mediaSecond={reference.mediaSecond?.items}
				width={reference.width}
				background={reference.background}
				backgroundSecond={reference.backgroundSecond}
				verticalAlignment={reference.verticalAlignment}
				verticalAlignmentSecond={reference.verticalAlignmentSecond}
			/>
		)
	},
	box({ reference }) {
		return (
			<Box
				title={reference.primaryText}
				text={reference.secondaryText}
				gallery={reference.media}
				link={reference.link}
				linkAlignment={reference.alignment}
				background={reference.background}
			/>
		)
	},
	pricingTable({ reference }) {
		return reference.pricingTable && <PricingTable {...reference.pricingTable} />
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({ content }) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<EscapeableHyphenRichText
				blocks={blocks}
				sourceField="json"
				referenceRenderers={referenceRenderers}
				renderElement={(element) => {
					const { type } = element.element
					if (nestedTypes.includes(type)) {
						return element.fallback
					}
					if (standaloneTypes.includes(type)) {
						const background = (element.reference as Block['reference']).blockBackgroundType

						return (
							<div
								className={clsx(
									styles.section,
									element.referenceType && styles[`is_reference_${element.referenceType}`],
									styles[`is_background_${background}`]
								)}>
								{type !== 'reference' ||
								!element.referenceType ||
								element.referenceType in referenceRenderers ? (
									element.fallback
								) : (
									<div className={styles.notImplemented}>
										<div className={styles.notImplemented_name}>{element.referenceType}</div>
										is not yet implemented
									</div>
								)}
							</div>
						)
					}
					return (
						<div className={clsx(styles.section, styles.is_wysiwyg)}>
							<Wysiwyg>{element.fallback}</Wysiwyg>
						</div>
					)
				}}
			/>
		</div>
	)
}
