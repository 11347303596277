import type { FunctionComponent } from 'react'
import { PricingTableColumnType } from '../../generated/content'
import type { PricingTableColumnResult } from '../data/content/PricingTableColumnFragment'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'
import { Icon } from './Icon'
import styles from './PricingTableColumn.module.sass'

export type PricingTableColumnProps = PricingTableColumnResult

export const PricingTableColumn: FunctionComponent<PricingTableColumnProps> = ({ type, text }) => {
	return (
		<>
			{type === PricingTableColumnType.checkmarkIcon ? (
				<div className={styles.checkmark}>
					<Icon name="checkmarkWithCircle" />
				</div>
			) : type === PricingTableColumnType.text && text ? (
				<EscapeableHyphenRichText source={text} />
			) : null}
		</>
	)
}
