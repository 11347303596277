import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { PricingTableGroupResult } from '../data/content/PricingTableGroupFragment'
import styles from './PricingTableGroup.module.sass'
import { PricingTableRow } from './PricingTableRow'

export type PricingTableGroupProps = PricingTableGroupResult & {
	columnCount?: number
	isFirstGroup?: boolean
}

export const PricingTableGroup: FunctionComponent<PricingTableGroupProps> = ({
	title,
	rows,
	columnCount,
	isFirstGroup,
}) => {
	return (
		<>
			{title && (
				<tr className={clsx(styles.row, styles.is_header, isFirstGroup && styles.isFirstGroup)}>
					<td className={styles.cell}>{title}</td>
					{[...new Array(columnCount)].map((_, index) => (
						<td key={index} className={styles.cell}></td>
					))}
				</tr>
			)}
			{rows.map((row) => (
				<tr key={row.id} className={styles.row}>
					<PricingTableRow key={row.id} {...row} isTitleShifted={title ? true : false} />
					{columnCount &&
						[...new Array(columnCount - row.columns.length)].map((_, index) => (
							<td key={index}></td>
						))}
				</tr>
			))}
		</>
	)
}
