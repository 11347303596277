import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react'
import styles from './Input.module.sass'

export type InputProps = Pick<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	| 'name'
	| 'type'
	| 'autoComplete'
	| 'value'
	| 'onChange'
	| 'disabled'
	| 'readOnly'
	| 'placeholder'
	| 'required'
	| 'hidden'
>

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			name,
			type = 'text',
			autoComplete,
			value,
			onChange,
			disabled,
			readOnly,
			placeholder,
			required,
			hidden,
		},
		ref
	) => {
		return (
			<input
				className={styles.input}
				name={name}
				type={type}
				autoComplete={autoComplete}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				required={required}
				hidden={hidden}
				// @TODO: patter for email
				// pattern={
				// 	type === 'email'
				// 		? '^([a-zA-Z0-9_-.]+)@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(([a-zA-Z0-9-]+.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$'
				// 		: undefined
				// }
				ref={ref}
			/>
		)
	}
)

Input.displayName = 'Input'
