import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { App } from '../app/components/App'
import { GenericPage } from '../app/components/GenericPage'
import { HomePage } from '../app/components/HomePage'
import { Layout } from '../app/components/Layout'
import { combineUrl } from '../app/data/combineUrl'
import { AppFragment } from '../app/data/content/AppFragment'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { GenericPageFragment } from '../app/data/content/GenericPageFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HomePageFragment } from '../app/data/content/HomePageFragment'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import { One, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage({ headerRoot, footerRoot, page }: CommonPageProps) {
	const content = (
		<>
			{page.homePage ? (
				<HomePage homePage={page.homePage} />
			) : page.appDetailPage ? (
				<App app={page.appDetailPage} />
			) : (
				<GenericPage {...page.genericPage} />
			)}
		</>
	)

	const pageLocales = [page.homePage, page.appDetailPage, page.genericPage].filter(
		filterNonEmpty
	)[0]

	return (
		<>
			{/* @TODO: seo */}
			<Head>
				<title>Goodlok - pieces</title>
				{/* @TODO favicon */}
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<Layout
				header={headerRoot?.localesByLocale}
				translations={pageLocales.root?.locales ?? []}
				footer={footerRoot?.localesByLocale}
				isMainSpacingTop={page.appDetailPage ? true : undefined}>
				{content}
			</Layout>

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const { path, locale } = combineUrl(context)

	// const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		// ...SiteFragment(urlInfo.locale),
		getHeaderRoot: [
			{
				by: { unique: One.One },
			},
			{ localesByLocale: [{ by: { locale: { code: locale } } }, HeaderFragment()] },
		],
		getLinkable: [
			{ by: { url: path } },
			{
				id: true,
				url: true,
				homePage: [{}, HomePageFragment(locale)],
				genericPage: [{}, GenericPageFragment(locale)],
				app: [{}, AppFragment(locale)],
			},
		],
		getFooterRoot: [
			{
				by: { unique: One.One },
			},
			{ localesByLocale: [{ by: { locale: { code: locale } } }, FooterFragment()] },
		],
	})

	const homePage = await (async () => {
		const initial = dataFromContember.getLinkable?.homePage

		if (!initial) {
			return null
		}

		return initial
	})()

	const appDetailPage = await (async () => {
		const initial = dataFromContember.getLinkable?.app

		if (!initial) {
			return null
		}

		return initial
	})()

	const genericPage = dataFromContember.getLinkable?.genericPage ?? null

	const page = {
		homePage,
		genericPage,
		appDetailPage,
	}

	const currentPage = Object.values(page).find((page) => page !== null)

	if (!currentPage) {
		throw new RespondWithNotFound('Page not found.')
	}

	return {
		props: {
			path,
			headerRoot: dataFromContember.getHeaderRoot,
			footerRoot: dataFromContember.getFooterRoot,
			page,
			serverTime: new Date().toString(),
		},
	}
})
