import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Background, MediumType, VerticalAlignment } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumItemResult } from '../data/content/MediumItemFragment'
import s from './BoxTile.module.sass'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'

export type BoxTileProps = {
	title?: string
	text?: string
	link?: LinkResult
	media?: MediumItemResult[]
	background?: Background
	backgroundSecond?: Background
	verticalAlignment?: VerticalAlignment
	verticalAlignmentSecond?: VerticalAlignment
}

export const BoxTile: FunctionComponent<BoxTileProps> = (props) => {
	return (
		<div
			className={clsx(
				s.BoxTile,
				s[`isBackground_${props.background}`],
				s[`isBackground_${props.backgroundSecond}`],
				s[`isAlign_${props.verticalAlignment}`],
				s[`isAlign_${props.verticalAlignmentSecond}`]
			)}>
			{props.link?.externalLink || props.link?.internalLink ? (
				<Link link={props.link}>
					<BoxInner {...props} />
				</Link>
			) : (
				<BoxInner {...props} />
			)}
		</div>
	)
}

export const BoxInner: FunctionComponent<BoxTileProps> = ({
	title,
	text,
	link,
	media,
	background,
}) => {
	return (
		<>
			<div className={s.TextBox}>
				<div className={s.TitleBox}>
					<h3 className={s.Title}>{title}</h3>
					{link?.title && (
						<Button text={link?.title} color={background === Background.blue ? 'white' : 'blue'} />
					)}
				</div>
				<p className={s.Text}>{text}</p>
			</div>
			<div className={s.Media}>
				{media?.map((item) => {
					const mediaType = item.medium?.type
					if (item.medium) {
						return (
							<div
								key={item.id}
								className={clsx(s.MediaItem, s[`is_${mediaType}`])}
								style={{
									'--BoxTile-image-width': item.medium.image?.width,
								}}>
								{mediaType === MediumType.image && item.medium.image && (
									<ContemberImage image={item.medium.image} />
								)}
								{mediaType === MediumType.video && item.medium.video && (
									<video
										className={s.Video}
										autoPlay
										loop
										muted
										playsInline
										poster={item.medium.video.poster?.url}>
										<source src={item.medium.video.src} type={item.medium.video.type} />
									</video>
								)}
							</div>
						)
					}
					return null
				})}
			</div>
		</>
	)
}
