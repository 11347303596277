import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { Background, ContentAlignment } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumListResult } from '../data/content/MediumListFragment'
import styles from './Box.module.sass'
import { BlockButton } from './Button'
import { Container } from './Container'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'
import { Gallery } from './Gallery'

export type BoxProps = {
	title?: string
	text?: string
	gallery?: MediumListResult
	link?: LinkResult
	linkAlignment?: ContentAlignment
	background?: Background
}

export const Box: FunctionComponent<BoxProps> = ({
	title,
	text,
	gallery,
	link,
	linkAlignment,
	background,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container guttersSize="mobileNarrow">
				<div className={styles.main}>
					<div className={clsx(styles.mainIn, styles[`is_background_${background}`])}>
						<div className={styles.content}>
							{title && (
								<h2 className={styles.title}>
									<RichTextRenderer source={title} />
								</h2>
							)}
							{text && (
								<div className={styles.text}>
									<EscapeableHyphenRichText source={text} />
								</div>
							)}
						</div>
						{gallery && (
							<div className={styles.gallery}>
								<Gallery mediumList={gallery} />
							</div>
						)}
					</div>
					{link && (
						<div className={styles.button}>
							<BlockButton link={link} aligning={linkAlignment} />
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}
