import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { EscapeableHyphenRichText } from './EscapeableHyphenRichtText'
import styles from './LeadContent.module.sass'

export type LeadContentProps = {
	title?: string
	leadParagraph?: string
	isTopSpacing?: boolean
}

export const LeadContent: FunctionComponent<LeadContentProps> = ({
	title,
	leadParagraph,
	isTopSpacing = false,
}) => {
	return (
		<div className={clsx(styles.wrapper, isTopSpacing && styles.is_topSpacing)}>
			{title && (
				<h1 className={styles.title}>
					<EscapeableHyphenRichText source={title} />
				</h1>
			)}
			{leadParagraph && (
				<div className={styles.leadParagraph}>
					<EscapeableHyphenRichText source={leadParagraph} />
				</div>
			)}
		</div>
	)
}
