import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import type { FooterResult } from '../data/content/FooterFragment'
import type { HeaderResult } from '../data/content/HeaderFragment'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'

export type LayoutProps = {
	header?: HeaderResult
	footer?: FooterResult
	children: ReactNode
	isMainSpacingTop?: boolean
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
}

export const Layout: FunctionComponent<LayoutProps> = ({
	header,
	footer,
	children,
	isMainSpacingTop,
	translations,
}) => {
	return (
		<div className={styles.wrapper}>
			<header className={styles.header}>
				{header && <Header header={header} translations={translations} />}
			</header>
			<SharedProgressLoadingIndicator />
			<main className={clsx(styles.main, isMainSpacingTop && styles.is_topSpacing)}>
				{children}
			</main>
			<footer className={styles.footer}>{footer && <Footer footer={footer} />}</footer>
		</div>
	)
}
